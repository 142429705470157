import { LuminousGallery } from "luminous-lightbox";

window.addEventListener("load", function () {
  // アセットがすべて読み込まれた後に実行するコード
  document.body.style.opacity = "1";
});
// 削除ここまで

// import React from 'react'
// import { render } from 'react-dom'
// import { MenuBtn } from './components/MenuBtn'

// const $menuBtn = document.getElementById('menu-btn')

// if ($menuBtn) {
//   render(<MenuBtn />, $menuBtn)
// }

// 公開時に削除
const $cssList = document.querySelectorAll(".css");
const now = new Date();
const params = {
  time: encodeURI(now.toLocaleString()),
};
const urlSearchParams = new URLSearchParams(params).toString();

$cssList.forEach((item) => {
  return (item.href = `${item.href}?${urlSearchParams}`);
});

// eslint-disable-next-line no-unused-vars
const $lightbox = document.querySelectorAll(".lightbox");
const $lightbox2 = document.querySelectorAll(".lightbox-2");

// caption のオプション（img 要素の alt 属性の値をキャプションに表示）
const lightboxOptions = {
  caption: (trigger) => {
    return trigger.querySelector("img").getAttribute("alt");
  },
};

if ($lightbox.length > 0) {
  //第3引数に caption のオプションを指定
  new LuminousGallery($lightbox, {}, lightboxOptions);
}

if ($lightbox2.length > 0) {
  //第3引数に caption のオプションを指定
  new LuminousGallery($lightbox2, {}, lightboxOptions);
}
